.hidden {
    display: none;
}

// stylelint-disable annotation-no-unknown

// scss-docs-start display-color
.d-none-light, .d-none-inline-light {
    display: none if($enable-important-utilities, !important, null); 
}

.d-none-dark {
    display: block if($enable-important-utilities, !important, null);
}

.d-none-inline-dark {
    display: inline if($enable-important-utilities, !important, null);
}

@if $enable-dark-mode {
    @include color-mode(dark) {
        .d-none-light {
            display: block if($enable-important-utilities, !important, null);
        }

        .d-none-inline-light {
            display: inline if($enable-important-utilities, !important, null);
        }

        .d-none-dark, .d-none-inline-dark {
            display: none if($enable-important-utilities, !important, null);
        }
    }
}

// scss-docs-end display-color

// stylelint-enable annotation-no-unknown
