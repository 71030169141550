// add zoom animation with opacity change on card img hover
// source: https://stackoverflow.com/a/43816376

.card-img-bg {
    background-color: var(--#{$prefix}secondary-bg);
}

.card .card-img-wrap {
    overflow: hidden;
    position: relative;
}

.card .card-img-wrap:has(.card-img-top) {
  border-top-left-radius: var(--#{$prefix}card-inner-border-radius);
  border-top-right-radius: var(--#{$prefix}card-inner-border-radius);
}

.card .card-img-wrap:has(.card-img-bottom) {
  border-bottom-right-radius: var(--#{$prefix}card-inner-border-radius);
  border-bottom-left-radius: var(--#{$prefix}card-inner-border-radius);
}

.card-zoom {
    cursor: pointer;
}

.card-img-wrap img {
    transition: transform 0.25s ease;
    width: 100%;
}

.card-icon {
    color: $secondary;
}

.card-zoom::after .card-img-wrap img {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.3);
    opacity: 0;
    transition: opacity 0.25s;
}

.card-zoom:hover .card-img-wrap img {
    transform: scale(1.1);
    opacity: 0.5;
}

.card-zoom:hover::after .card-img-wrap img {
    opacity: 1;
}

.card-emphasize {
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow, 0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
}

.card-emphasize:hover {
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

// stylelint-disable annotation-no-unknown
.card-body-link {
    color: $body-color if($enable-important-utilities, !important, null);
}

.card-body-margin {
    margin-bottom: $spacer * 1.5; // equals mb-4
}

@if $enable-dark-mode {
    @include color-mode(dark) {
        .card-body-link {
            color: $gray-500 if($enable-important-utilities, !important, null);
        }
    }
}

.card-zoom .card-body-link {
    &:hover,
    &:focus {
        color: shade-color($primary, $link-shade-percentage) if($enable-important-utilities, !important, null);
    }
}

@if $enable-dark-mode {
    @include color-mode(dark) {
        .card-zoom .card-body-link {
            &:hover,
            &:focus {
                color: $primary-bg-subtle-dark if($enable-important-utilities, !important, null);
            }
        }
    }
}

// stylelint-enable annotation-no-unknown

// make tag-link and card-button clickable on top of the stretched-link.
.card .tag-link, .card .card-button {
    z-index: 2;
    position: relative;
}

.card-button-link {
    text-decoration: underline if($enable-important-utilities, !important, null);
}

// support horizontally scrollable card groups
.card-container-wrapper {
	position: relative;
}

div.card-container-wrapper::after {
	content: "";
	position: absolute;
	inset: 0;
	height: calc(100% - 2rem);
	background: linear-gradient(
		90deg, 
		var(--bs-body-bg) 0%,
		transparent 1.5rem,
		transparent calc(100% - 1.5rem),
		var(--bs-body-bg) 100%
	);
	pointer-events: none;
}

.card-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
	gap: 1.5rem;
    margin-bottom: 2rem;
	scroll-snap-type: x proximity;
}

.card-block-1 {
    width: 80%;
	scroll-snap-align: center;
}

.card-block-2 {
    width: 40%;
	scroll-snap-align: center;
}

.card-block-3 {
    width: 27%;
	scroll-snap-align: center;
}

.card-block-4 {
    width: 20%;
	scroll-snap-align: center;
}

.card-block-5 {
    width: 16%;
	scroll-snap-align: center;
}
