.blockquote {
    border-left: 0.3rem solid;
    border-color: $secondary;
    padding: 1rem 1.5rem;
}

.blockquote-alert {
    --bs-blockquote-alert-color: $secondary;

    border-left: 0.3rem solid;
    border-color: var(--bs-blockquote-alert-color);
    padding: .5rem 1.5rem;
}

.blockquote-alert > p:nth-of-type(2) {
    margin-bottom: 0;
}

.blockquote-alert-heading {
    font-weight: bold;
    color: var(--bs-blockquote-alert-color);
}

.blockquote-alert-caution {
    --bs-blockquote-alert-color: var(--#{$prefix}danger);
}

.blockquote-alert-important {
    --bs-blockquote-alert-color: var(--#{$prefix}primary);
}

.blockquote-alert-note {
    --bs-blockquote-alert-color: var(--#{$prefix}info);
}

.blockquote-alert-tip {
    --bs-blockquote-alert-color: var(--#{$prefix}success);
}

.blockquote-alert-warning {
    --bs-blockquote-alert-color: var(--#{$prefix}warning);
}

.blockquote-alert a.btn-link {
    --bs-link-color: var(--bs-blockquote-alert-color);
    --bs-link-hover-color: color-mix(in srgb, var(--bs-link-color), black 20%);

    color: var(--bs-link-color) !important;

    &:hover,
    &:focus {
        color: var(--bs-link-hover-color) !important;
    }
}
