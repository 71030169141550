// scss-docs-start icons
$form-check-input-checked-bg-image:             url("#{$base-url}icons/form-check-input-checked-bg-image.svg") !default;
$form-check-radio-checked-bg-image:             url("#{$base-url}icons/form-check-radio-checked-bg-image.svg") !default;
$form-check-input-indeterminate-bg-image:       url("#{$base-url}icons/form-check-input-indeterminate-bg-image.svg") !default;
$form-switch-bg-image:                          url("#{$base-url}icons/form-switch-bg-image.svg") !default;
$form-switch-bg-image-dark:                     url("#{$base-url}icons/form-switch-bg-image-dark.svg") !default;
$form-switch-focus-bg-image:                    url("#{$base-url}icons/form-switch-focus-bg-image.svg") !default;
$form-switch-checked-bg-image:                  url("#{$base-url}icons/form-switch-checked-bg-image.svg") !default;
$form-select-indicator:                         url("#{$base-url}icons/form-select-indicator.svg") !default;
$form-select-indicator-dark:                    url("#{$base-url}icons/form-select-indicator-dark.svg") !default;
$form-feedback-icon-valid:                      url("#{$base-url}icons/form-feedback-icon-valid.svg") !default;
$form-feedback-icon-invalid:                    url("#{$base-url}icons/form-feedback-icon-invalid.svg") !default;
$navbar-light-toggler-icon-bg:                  url("#{$base-url}icons/navbar-light-toggler-icon-bg.svg") !default;
$navbar-dark-toggler-icon-bg:                   url("#{$base-url}icons/navbar-dark-toggler-icon-bg.svg") !default;
$accordion-button-icon:                         url("#{$base-url}icons/accordion-button-icon.svg") !default;
$accordion-button-icon-dark:                    url("#{$base-url}icons/accordion-button-icon-dark.svg") !default;
$accordion-button-active-icon:                  url("#{$base-url}icons/accordion-button-active-icon.svg") !default;
$accordion-button-active-icon-dark:             url("#{$base-url}icons/accordion-button-active-icon-dark.svg") !default;
$carousel-control-prev-icon-bg:                 url("#{$base-url}icons/carousel-control-prev-icon-bg.svg") !default;
$carousel-control-next-icon-bg:                 url("#{$base-url}icons/carousel-control-next-icon-bg.svg") !default;
$btn-close-bg:                                  url("#{$base-url}icons/btn-close-bg.svg") !default;
$btn-toggle:                                    url("#{$base-url}icons/btn-toggle.svg") !default;
$btn-toggle-dark:                               url("#{$base-url}icons/btn-toggle-dark.svg") !default;

// scss-docs-end icons
