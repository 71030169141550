.datatable-container {
    border-bottom: none if($enable-important-utilities, !important, null);
}

.datatable-bottom {
    padding-top: 0 if($enable-important-utilities, !important, null);
}

.datatable-sorter {
    padding-left: 0;
    padding-right: 1rem;
}

@if $enable-dark-mode {
    @include color-mode(dark) {
        .table-striped, .table-striped-columns {
            --bs-table-striped-bg: var(--bs-tertiary-bg);
            --bs-table-striped-color: var(--bs-body-color);
        }

        .table-hover {
            --bs-table-hover-bg: var(--bs-tertiary-bg);
            --bs-table-striped-color: var(--bs-body-color);
        }

        .datatable-pagination-list-item {
            &:hover,
            &:focus,
            &.datatable-active {
                color: var(--bs-body-bg) !important;
            }
        } 
    }
}
