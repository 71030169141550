.pagination {
    --bs-pagination-bg: var(--bs-primary-bg-subtle);
    --bs-pagination-color: var(--bs-body-color);
    --bs-pagination-border-color: none;
    --bs-pagination-hover-color: var(--bs-pagination-active-color);
    --bs-pagination-hover-bg: var(--bs-pagination-active-bg);
    --bs-pagination-hover-border-color: none;
}

.pagination .page-item {
    margin: 0.4rem;
}