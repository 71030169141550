// Bootstrap variables overrides for theme
$enable-negative-margins:       true;
$enable-important-utilities:    true !default;

// Remove the border from the focused navigation toggler
$navbar-toggler-focus-width:    0 !default;

$base-url:                      $base-url;

// scss-docs-start font
$font-family-sans-serif:        $theme-font, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-size-base:                $font-size-base !default;;
$headings-font-weight:          600 !default;
$font-weight-lighter:           lighter !default;
$font-weight-light:             200 !default;
$font-weight-normal:            300 !default;
$font-weight-bold:              600 !default;
$font-weight-bolder:            bolder !default;

strong {
    font-weight:                600 if($enable-important-utilities, !important, null);
}
// scss-docs-end font

$black:                       #000 !default;
$btn-toggle-color:              $black !default;

$card-color:                    var(--bs-body-color);

$carousel-dark-indicator-active-bg:  #fff !default;
$carousel-dark-caption-color:        #fff !default;
$carousel-dark-control-icon-filter:  invert(0) grayscale(100) !default;

// scss-docs-start color-mode
$primary-text-emphasis-dark:        mix(white, $primary, $dark-mode-tint) !default;
$secondary-text-emphasis-dark:      mix(white, $secondary, $dark-mode-tint) !default;
$link-color-dark:                   mix(white, $primary, $dark-mode-tint) !default;
$primary-bg-subtle-dark:            mix(black, $primary, $dark-mode-shade) !default;
$primary-border-subtle-dark:        mix(black, $primary, $dark-mode-shade / 2) !default;
// scss-docs-end color-mode

// scss-docs-start horizontal-nav
$dropdown-transition: opacity .15s ease-in-out !default;
$dropdown-horizontal-margin-top: calc((-1.5 * 1rem) - 2px);
$dropdown-horizontal-padding-y: calc(1rem + 2px);
// scss-docs-end horizontal-nav
