/* Set hover color for primary button to white (overrides color contrast defined in mixin) */
.btn-outline-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    color: #fff if($enable-important-utilities, !important, null);
}

.btn-social {
    background-color: transparent;
    
    --bs-btn-bg: transparent;
    --bs-btn-border-width: none;
    --bs-btn-color: var(--bs-secondary);
    --bs-btn-border-color: none;
    --bs-btn-hover-bg: none;
    --bs-btn-hover-border-color: none;
    --bs-btn-active-bg: none;
    --bs-btn-active-border-color: none;
}

.btn-social:hover,
.btn-social:focus,
.btn-social:active,
.btn-social.active,
.open > .dropdown-toggle.btn-primary {
    background-color: transparent;
    color: var(--bs-primary) if($enable-important-utilities, !important, null);
}

a.btn {
    margin-top: $spacer * .25;
    margin-bottom: $spacer * .25;
}

.btn-close {
    background-color: transparent if($enable-important-utilities, !important, null);
    background-image: var(--#{$prefix}btn-close-bg) if($enable-important-utilities, !important, null);
    background-position: center if($enable-important-utilities, !important, null);
    background-repeat: no-repeat if($enable-important-utilities, !important, null);
    background-size: $btn-close-width if($enable-important-utilities, !important, null);
}

.btn-link {
    --bs-btn-padding-x: 0;
    --bs-btn-padding-y: 0;

    margin: 0 !important;
}
