.img-wrap {
    overflow: hidden;
    position: relative;
    display: inline-block; /* Esto asegura que el contenedor se ajuste al tamaño de la imagen */
}

.img-wrap img {
    width: auto;
    height: auto;
    max-width: 100%; /* Asegura que la imagen no se desborde del contenedor */
    max-height: 100%; /* Asegura que la imagen no se desborde del contenedor */
    object-fit: contain; /* No recorta la imagen, la ajusta dentro del contenedor manteniendo el aspecto original */
}

.card-img-h100 {
    width: auto;
    height: 100%;
    object-fit: contain;
}

.mirrorred {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.background-img {
    --overlay-offset: #{$overlay-offset};

    height: calc(100vh - var(--overlay-offset));
    width: 100vw;
    object-fit: cover;
}
