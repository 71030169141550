//
// Headings
//
h1 {
    margin-top: $spacer * 2.5;
    scroll-margin-top: var(--navbar-offset);
}

h2 {
    margin-top: $spacer * 2;
    scroll-margin-top: var(--navbar-offset);
}

h3 {
    margin-top: $spacer * 1.75;
    scroll-margin-top: var(--navbar-offset);
}

h4 {
    margin-top: $spacer * 1.5;
    scroll-margin-top: var(--navbar-offset);
}

h5 {
    margin-top: $spacer * 1.25;
    scroll-margin-top: var(--navbar-offset);
}

h6 {
    margin-top: $spacer;
    scroll-margin-top: var(--navbar-offset);
}

.display-1, .display-2, .display-3, .display-4, .display-5, .display-6 {
    scroll-margin-top: var(--navbar-offset);
}
